import { slides } from "../data";
import { Carousel } from "./Carousel";

const Hero = () => {
    return (
        <div className="hero" id="home">
            <div className="section-center">
                <div className="hero-wrapper">
                    <div className="hero-wrapper-container-img">
                        <Carousel />
                    </div>
                    <div className="hero-wrapper-container-info">
                        <h1 className="heading-h1">
                            <span className="yellow-color">Design</span> and
                            architecture
                        </h1>
                        <p>
                            Giving you ideas and design it, that is what we do
                            with creative and knowledge. Check out our amazing
                            work and services in a flash.
                        </p>
                        <a href="#design" type="text" className="btn">
                            Look At designs
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Hero;
