import { navLinks, navSocial } from "../data";
import christianLogo from "../images/29ark-logo-1.png";
import { useRef, useEffect, useState } from "react";

const NavItem = ({ href, text }) => {
    return (
        <li>
            <a href={href} className="nav-link">
                {text}
            </a>
        </li>
    );
};

const NavSocialItem = ({ href, icon, target }) => {
    return (
        <div>
            <a
                href={href}
                target={target}
                className="nav-icon"
                rel="noreferrer"
            >
                <i className={icon}></i>
            </a>
        </div>
    );
};

const NavBar = () => {
    let refMenu = useRef();
    let refNav = useRef();

    const handleToggleMenu = (e) => {
        if (refMenu.current.classList.contains("inactive")) {
            refNav.current.classList.add("nav-mobile");
            refMenu.current.classList.replace("inactive", "active");
        } else {
            refNav.current.classList.remove("nav-mobile");
            refMenu.current.classList.replace("active", "inactive");
            refNav.current.style.overflow("hidden");
        }

        // $(".products-nutrition-modal").click(function (event) {
        //     if (event.target !== event.currentTarget) {
        //         return;
        //     }
        //     $(this).css("display", "none");
        // });
    };

    const [sticky, setSticky] = useState(false);

    const getSticky = () => {
        if (window.scrollY >= 730) {
            setSticky(true);
        } else {
            setSticky(false);
        }
    };

    window.addEventListener("scroll", getSticky);

    return (
        <>
            <nav className="navbar">
                <div className={sticky ? "nav-center" : "nav-center navBorder"}>
                    <div className="nav-header">
                        <img
                            src={christianLogo}
                            className="nav-logo"
                            alt="Christian Cervantes Developer"
                        />
                        <button
                            type="button"
                            className="nav-toggle inactive"
                            id="nav-toggle"
                            ref={refMenu}
                            onClick={handleToggleMenu}
                        >
                            <i className="fas fa-bars"></i>
                        </button>
                    </div>
                    {/* <!-- left this comment on purpose --> */}
                    <ul className="nav-links" id="nav-links" ref={refNav}>
                        {navLinks.map((el) => {
                            return <NavItem {...el} key={el.id} />;
                        })}
                    </ul>
                </div>
            </nav>
            <div className="nav-icons">
                <div className="nav-icons-wrapper">
                    {navSocial.map((el) => {
                        return <NavSocialItem {...el} key={el.id} />;
                    })}
                </div>
            </div>
        </>
    );
};
export default NavBar;
