// import Title from "./general/Title";
import ContactForm from "./general/ContactForm";

const Contact = () => {
    return (
        <section className="section contact" id="contact">
            {/* <Title title="Contact" spanTitle="me" /> */}
            <div className="section-center projects-center">
                <div className="contact-wrapper">
                    <div className="contact-title">
                        <h2>
                            <span className="yellow-color">Contact</span> Us!
                        </h2>
                    </div>
                    <p className="paragraph-center">
                        Get in touch and we will gladly provide you with all the
                        information you need.
                    </p>
                    <ContactForm />
                </div>
            </div>
        </section>
    );
};
export default Contact;
