import { bimImages } from "../data";
import Number from "./general/Number";

const BimImagesItem = ({ image, alt }) => {
    return (
        <div className="bim-wrapper-col-b-wrapper-item">
            <img src={image} alt={alt} />
        </div>
    );
};

const Bim = () => {
    return (
        <div className="bim" id="bim">
            <div className="">
                <div className="bim-wrapper">
                    <div className="bim-wrapper-col-a">
                        <div className="bim-wrapper-col-a-inside">
                            <h2 className="text-rot">
                                <span className="yellow-color">BIM</span> Design
                            </h2>
                            <p>
                                we unify all development information and
                                standardize processes quickly and transparently.
                                <br />
                                <br />
                                With BIM we: automating programming processes,
                                conceptual design, detailed design,
                                manufacturing, construction logistics, operation
                                and maintenance, renovation and/or demolition.
                            </p>
                        </div>
                    </div>
                    <div className="bim-wrapper-col-b">
                        <div className="bim-wrapper-col-b-wrapper">
                            {bimImages.map((el) => {
                                return <BimImagesItem {...el} key={el.id} />;
                            })}
                        </div>
                    </div>
                    <Number number="02" />
                </div>
            </div>
        </div>
    );
};
export default Bim;
