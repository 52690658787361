import { constructionImages } from "../data";
import Number from "./general/Number";

const ConstructionImagesItem = ({ image, alt, classItem }) => {
    return (
        <div className={`construction-wrapper-col-b-wrapper-item ${classItem}`}>
            <img src={image} alt={alt} />
        </div>
    );
};

const Construction = () => {
    return (
        <div className="construction" id="construction">
            <div className="">
                <div className="construction-wrapper">
                    <div className="construction-wrapper-col-a">
                        <div className="construction-wrapper-col-a-inside">
                            <h2 className="text-rot">
                                <span className="yellow-color">
                                    Construction
                                </span>
                            </h2>
                            <p>
                                We are ready to help you move one step closer
                                with years of experience, all to finish your
                                next project.
                            </p>
                        </div>
                    </div>
                    <div className="construction-wrapper-col-b">
                        <div className="construction-wrapper-col-b-wrapper">
                            {constructionImages.map((el) => {
                                return (
                                    <ConstructionImagesItem
                                        {...el}
                                        key={el.id}
                                    />
                                );
                            })}
                        </div>
                    </div>
                    <Number number="03" />
                </div>
            </div>
        </div>
    );
};
export default Construction;
