import hero1 from "./images/facade-1.webp";
import hero2 from "./images/hero-living.webp";
import hero3 from "./images/exterior-2.webp";
import hero4 from "./images/hero-kitchen.webp";
import design1 from "./images/facade-2.webp";
import design2 from "./images/exterior-1.webp";
import design3 from "./images/interior-1.webp";
import design4 from "./images/bar-1.webp";
import design5 from "./images/kitchen-1.webp";
import design6 from "./images/bedroom-1.webp";
import design7 from "./images/bathroom-1.webp";
import bim1 from "./images/bim-1.webp";
import bim2 from "./images/bim-2.webp";
import bim3 from "./images/bim-3.webp";
import construction1 from "./images/construction-1.webp";
import construction2 from "./images/construction-2.webp";
import construction3 from "./images/construction-3.webp";
import construction4 from "./images/construction-4.webp";

// import hero4 from "./images/hero-front.webp";

export const navLinks = [
    {
        id: 1,
        href: "#home",
        text: "home",
    },
    {
        id: 2,
        href: "#design",
        text: "Design",
    },
    {
        id: 3,
        href: "#bim",
        text: "BIM Model",
    },
    {
        id: 4,
        href: "#construction",
        text: "Construction",
    },
    {
        id: 5,
        href: "#contact",
        text: "Contact",
    },
];

export const navSocial = [
    {
        id: 1,
        href: "mailto: 29arkdesignarc@gmail.com",
        icon: "fa fa-envelope",
        target: "",
    },
    {
        id: 2,
        href: "https://instagram.com/arquitectosuarez_?igshid=YmMyMTA2M2Y=",
        icon: "fab fa-instagram",
        target: "_blank",
    },
];

export const slides = [
    {
        id: 1,
        alt: "Start with your email",
        image: hero1,
    },
    {
        id: 2,
        alt: "Let's make things secure",
        image: hero2,
    },
    {
        id: 3,
        alt: "Smash the button below",
        image: hero3,
    },
    {
        id: 4,
        alt: "Smash the button below",
        image: hero4,
    },
];

export const designImages = [
    {
        id: 1,
        alt: "Start with your email",
        image: design1,
    },
    {
        id: 2,
        alt: "Let's make things secure",
        image: design2,
    },
    {
        id: 3,
        alt: "Smash the button below",
        image: design3,
    },
    {
        id: 4,
        alt: "Smash the button below",
        image: design4,
    },
    {
        id: 5,
        alt: "Smash the button below",
        image: design5,
    },
    {
        id: 6,
        alt: "Smash the button below",
        image: design6,
    },
    {
        id: 7,
        alt: "Smash the button below",
        image: design7,
    },
];

export const bimImages = [
    {
        id: 1,
        image: bim1,
        alt: "",
    },
    {
        id: 2,
        image: bim2,
        alt: "",
    },
    {
        id: 3,
        image: bim3,
        alt: "",
    },
];

export const constructionImages = [
    {
        id: 1,
        image: construction1,
        alt: "",
        classItem: "col-a",
    },
    {
        id: 2,
        image: construction2,
        alt: "",
        classItem: "col-b",
    },
    {
        id: 3,
        image: construction3,
        alt: "",
        classItem: "col-c",
    },
    {
        id: 4,
        image: construction4,
        alt: "",
        classItem: "col-d",
    },
];

export const footerLinks = [
    {
        id: 1,
        href: "#home",
        text: "home",
    },
    {
        id: 2,
        href: "#design",
        text: "Design",
    },
    {
        id: 3,
        href: "#bim",
        text: "BIM Model",
    },
    {
        id: 4,
        href: "#construction",
        text: "Construction",
    },
    {
        id: 5,
        href: "#contact",
        text: "Contact",
    },
];

export const footerSocial = [
    {
        id: 1,
        href: "#contact",
        icon: "fa fa-envelope",
    },
    {
        id: 2,
        href: "https://instagram.com/arquitectosuarez_?igshid=YmMyMTA2M2Y=",
        icon: "fab fa-instagram",
    },
];
