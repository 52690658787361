import Bim from "./components/Bim";
import Construction from "./components/Construction";
import Contact from "./components/Contact";
import Design from "./components/Design";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import NavBar from "./components/NavBar";

function App() {
    return (
        <div className="App">
            <NavBar />
            <Hero />
            <Design />
            <Bim />
            <Construction />
            <Contact />
            <Footer />
        </div>
    );
}

export default App;
