import { Carousel } from "./Carousel";
import { CarouselDesign } from "./CarouselDesign";
import Number from "./general/Number";

const Design = () => {
    return (
        <div className="design" id="design">
            <div className="">
                <div className="design-wrapper">
                    <div className="design-wrapper-col-a">
                        <div className="design-wrapper-col-a-inside">
                            <h2 className="text-rot">
                                <span className="yellow-color">Design</span> ARK
                            </h2>
                            <p>
                                In 29 ark we make designs with great quality and
                                freshness for all kinds of projects:
                            </p>
                            <ul>
                                <li>
                                    <span className="de-line">- </span>
                                    <span className="yellow-color">
                                        Residential
                                    </span>{" "}
                                    design
                                </li>
                                <li>
                                    <span className="yellow-color">
                                        Commercial
                                    </span>{" "}
                                    Design
                                </li>
                                <li>
                                    <span className="de-line">- </span>
                                    <span className="yellow-color">
                                        Urban
                                    </span>{" "}
                                    Design
                                </li>
                                <li>
                                    <span className="yellow-color">
                                        Interior
                                    </span>{" "}
                                    Design
                                </li>
                                <li>
                                    <span className="de-line">- </span>
                                    <span className="yellow-color">
                                        Landscape
                                    </span>{" "}
                                    Design
                                </li>
                                <li>
                                    <span className="yellow-color">
                                        Planimetry
                                    </span>{" "}
                                </li>
                                <li>
                                    <span className="de-line">- </span>
                                    <span className="yellow-color">
                                        3D
                                    </span>{" "}
                                    Rendering
                                </li>
                            </ul>
                            <a href="#contact" type="text" className="btn">
                                Contact Us
                            </a>
                        </div>
                    </div>
                    <div className="design-wrapper-col-b">
                        <CarouselDesign />
                    </div>
                    <Number number="01" />
                </div>
            </div>
        </div>
    );
};
export default Design;
