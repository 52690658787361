import { useRef, useState } from "react";
import emailjs from "emailjs-com";
// import ReCAPTCHA from "react-google-recaptcha";

const ContactForm = () => {
    let refName = useRef();
    let refForm = useRef();

    const enviarEmail = (e) => {
        e.preventDefault();
        try {
            emailjs
                .sendForm(
                    "service_id4dgwo",
                    "template_bwuc6qf",
                    e.target,
                    "VQIjuoQtKG8Z-ZOyO"
                )
                .then((res) => {
                    console.log(res);
                });

            // if (!e.ok) throw { status: e.status, statusText: e.statusText };

            const test = () => {
                refForm.current.insertAdjacentHTML(
                    "afterend",
                    "<div class='box-success-message'><p class='success-message'>Thanks, I'll get you back soon</p></div>"
                );
            };
            test();
        } catch (err) {
            let message = err.statusText || "Ocurrio un error";

            //mostramos el error debajo de la tabla
            refForm.current.insertAdjacentHTML(
                "afterend",
                `<p><b> Error ${err.status}: ${message} </b></p>`
            );
        }
    };

    const validations = () => {
        if (refName.current.value <= 2) {
            let contactForm = document.getElementById("contact-form-id");
            contactForm.insertAdjacentHTML(
                "afterend",
                "<p>name must have more than two characters</p>"
            );
        }
    };

    // const [validCaptcha, setValidCaptcha] = useState(null);

    // const captcha = useRef(null);

    // function onChange() {
    //     if (captcha.current.getValue()) {
    //         console.log("User is not a robot");
    //     }
    // }

    return (
        <div className="contact-form">
            <div className="contact-form-wrapper">
                <form id="contact-form-id" onSubmit={enviarEmail} ref={refForm}>
                    <div className="">
                        <div className="form-group">
                            <label>Name*</label>
                            <input
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                placeholder="Enter your name"
                                required
                                ref={refName}
                            />
                        </div>
                        <div className="form-group">
                            <label>Email*</label>
                            <input
                                type="text"
                                className="form-control"
                                id="email"
                                name="email"
                                placeholder="Enter your email"
                                required
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Message*</label>
                        <textarea
                            type="text"
                            className="form-control"
                            id="message"
                            name="message"
                            placeholder="Enter your message"
                            required
                        ></textarea>
                    </div>
                    {/* <ReCAPTCHA
                        sitekey="6LdTNO0jAAAAAJAYsJ3bPQ6-e9QU9ivaT1wjCRR1"
                        onChange={onChange}
                    /> */}
                    <div className="form-textarea">
                        <button type="submit" className="btn">
                            SUBMIT
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};
export default ContactForm;
