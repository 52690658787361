import { footerLinks, footerSocial } from "../data";

const FooterItem = ({ href, text }) => {
    return (
        <li>
            <a href={href} className="footer-link">
                {text}
            </a>
        </li>
    );
};

const FooterSocialItem = ({ href, icon }) => {
    return (
        <li>
            <a
                href={href}
                target="_blank"
                className="footer-icon"
                rel="noreferrer"
            >
                <i className={icon}></i>
            </a>
        </li>
    );
};

export default function Footer() {
    return (
        <footer className="section footer">
            <ul className="footer-links">
                {footerLinks.map((el) => {
                    return <FooterItem {...el} key={el.id} />;
                })}
            </ul>
            <ul className="footer-icons">
                {footerSocial.map((el) => {
                    return <FooterSocialItem {...el} key={el.id} />;
                })}
            </ul>
            <p className="copyright">
                &copy; Copyright <span id="date">2023</span>. Developed by{" "}
                <b>
                    <a
                        href="https://christiancervantes.dev/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Christian Cervantes
                    </a>
                </b>
                .
            </p>
        </footer>
    );
}
