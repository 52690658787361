import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel as ReactCarousel } from "react-responsive-carousel";
import "../styles/components/_carousels.scss";
import { slides } from "../data";

export const Carousel = () => (
    <div className="card">
        <ReactCarousel
            className="react-carousel"
            showArrows={false}
            showStatus={false}
            showThumbs={false}
            swipeable={true}
            emulateTouch={true}
        >
            {slides.map((el) => (
                <div key={el.id} className="react-carousel-slide">
                    <div className="image-wrapper">
                        <img src={el.image} />
                    </div>
                </div>
            ))}
        </ReactCarousel>
    </div>
);
